<template>
    <div>
        <div class="modal fade" id="CommissionSpecStatusModal" tabindex="-1" aria-labelledby="CommissionSpecStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="CommissionSpecStatusModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

import commissionSpecServices from '../Script/CommissionSpecService.js';

export default {
    name: 'CommissionSpecStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            StatusData : [],
            Status : '',
        }
    },
    methods: {
        async statusClick(id, status){
            this.ModalTitle = 'Edit Status Komisi Spek: ';
            this.Id = id;
            
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_commission_spec');
            this.Status = status;
            
            window.$('#CommissionSpecStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await commissionSpecServices.GetCommissionSpecDetail(this.Id); 
            
            const commissionContact = {
                head_contact_name : data.head_contact_name,
                contact_person_name : data.contact_person_name,
                phone_number : data.phone_number,
                position : data.position,
                bank_account_number : data.bank_account_number,
                bank_account_name : data.bank_account_number,
                npwp : data.npwp,
            }

            const commissionSpec = {
                commission_id : parseInt(this.Id),
                commission_value : parseFloat(data.commission_value),
                status : this.Status,
                commission_detail_estimation_id : null,
                new_commission_contact : commissionContact,
                commission_type : 'commission_spec',
                attachment : data.attachment,
                notes : data.notes,
            }
            
            const variables = {
                id: parseInt(this.Id),
                data : commissionSpec
            }
            
            commissionSpecServices.editQuery(variables).then(res => {
                this.$loading(false);
                window.$('#CommissionSpecStatusModal').modal('hide');
                this.$swal("Info", response.successUpdate, "success");
                this.$props.reload();
            }).catch(error => {
                this.$loading(false);
                var errorMessage = globalfunc.errorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            })
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>